<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" :style="`
    --var-calendar-other-text-color: ${form.calendar_settings && form.calendar_settings.other_text_color ? form.calendar_settings.other_text_color : '#121525'};
    --var-calendar-other-icon-color: ${form.calendar_settings && form.calendar_settings.other_icon_color ? form.calendar_settings.other_icon_color : '#5a5a5a'};
    --var-calendar-other-icon-color-light: ${form.calendar_settings && form.calendar_settings.other_icon_color ? form.calendar_settings.other_icon_color+25 : '#5a5a5a25'};
    `">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ type == 1 ? 'Welcome' : (type == 2 ? 'Announcement' : 'Tasks') }} Settings</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-setting-form" ref="client-portal-setting-form">
                <div class="modal_body">
                    <div class="tabs_content">
                        <div class="section_content w-100" v-show="type == 3">
                            <div class="calendar_activity_setting">
                                <div class="setting_wpr mb-3">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Widget Title <a class="reset-default" @click="form.calendar_settings.title = defaultForm.calendar_settings.title">Reset to default</a></label>
                                            <div class="field_wpr m-0">
                                                <Field autocomplete="off" type="text" name="task_widget_title" v-model="form.calendar_settings.title" placeholder="ex: Today's Tasks!" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <div class="setup_controller">
                                            <h4 @click="edit_morning = true;" v-if="!edit_morning">
                                                {{ form.calendar_settings.morning_text }}
                                                <span class="edit"><i class="fas fa-pen"></i></span>
                                                <a class="reset-default ml-auto" @click="form.calendar_settings.morning_text = defaultForm.calendar_settings.morning_text">Reset to default</a>
                                            </h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="calendar_morning_text" v-model="form.calendar_settings.morning_text" placeholder="Calendar morning text" rules="required" label="calendar morning text" />
                                                    <ErrorMessage name="calendar_morning_text" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('morning_text', 'edit_morning')" v-if="edit_morning" :disabled="calendarTitleLoader.morning_text">{{ calendarTitleLoader.morning_text ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="capsule_elm mt-2 mb-2">
                                                <h5>Show Morning Label</h5>
                                                <label for="morning_label" class="switch_option capsule_btn ml-auto">
                                                    <input type="checkbox" id="morning_label" :true-value="1" :false-value="0" v-model="form.calendar_settings.has_morning_label" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="session" style="background-color: var(--var-calendar-morning-icon-color-light); color: var(--var-calendar-morning-text-color);">Morning</span>
                                            </div>
                                            <div class="setting_wpr">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.morning_text_color = defaultForm.calendar_settings.morning_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_morning_text_color" v-model="form.calendar_settings.morning_text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.morning_text_color" :classes="{ 'has-error': errors.calendar_morning_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_morning_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Tag Color <a class="reset-default" @click="form.calendar_settings.morning_icon_color = defaultForm.calendar_settings.morning_icon_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_morning_icon_color" v-model="form.calendar_settings.morning_icon_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.morning_icon_color" :classes="{ 'has-error': errors.calendar_morning_icon_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_morning_icon_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="setup_controller">
                                            <h4 @click="edit_afternoon = true;" v-if="!edit_afternoon">
                                                {{ form.calendar_settings.afternoon_text }}
                                                <span class="edit"><i class="fas fa-pen"></i></span>
                                                <a class="reset-default ml-auto" @click="form.calendar_settings.afternoon_text = defaultForm.calendar_settings.afternoon_text">Reset to default</a>
                                            </h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="calendar_afternoon_text" v-model="form.calendar_settings.afternoon_text" placeholder="Calendar afternoon text" rules="required" label="calendar afternoon text" />
                                                    <ErrorMessage name="calendar_afternoon_text" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('afternoon_text', 'edit_afternoon')" v-if="edit_afternoon" :disabled="calendarTitleLoader.afternoon_text">{{ calendarTitleLoader.afternoon_text ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="capsule_elm mt-2 mb-2">
                                                <h5>Show Afternoon Label</h5>
                                                <label for="afternoon_label" class="switch_option capsule_btn ml-auto">
                                                    <input type="checkbox" id="afternoon_label" :true-value="1" :false-value="0" v-model="form.calendar_settings.has_afternoon_label" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="session" style="background-color: var(--var-calendar-afternoon-icon-color-light); color: var(--var-calendar-afternoon-text-color);">Afternoon</span>
                                            </div>
                                            <div class="setting_wpr">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.afternoon_text_color = defaultForm.calendar_settings.afternoon_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_afternoon_text_color" v-model="form.calendar_settings.afternoon_text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.afternoon_text_color" :classes="{ 'has-error': errors.calendar_afternoon_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_afternoon_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Tag Color <a class="reset-default" @click="form.calendar_settings.afternoon_icon_color = defaultForm.calendar_settings.afternoon_icon_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_afternoon_icon_color" v-model="form.calendar_settings.afternoon_icon_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.afternoon_icon_color" :classes="{ 'has-error': errors.calendar_afternoon_icon_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_afternoon_icon_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="setup_controller">
                                            <h4 @click="edit_evening = true;" v-if="!edit_evening">
                                                {{ form.calendar_settings.evening_text }}
                                                <span class="edit"><i class="fas fa-pen"></i></span>
                                                <a class="reset-default ml-auto" @click="form.calendar_settings.evening_text = defaultForm.calendar_settings.evening_text">Reset to default</a>
                                            </h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="calendar_evening_text" v-model="form.calendar_settings.evening_text" placeholder="Calendar evening text" rules="required" label="calendar evening text" />
                                                    <ErrorMessage name="calendar_evening_text" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('evening_text', 'edit_evening')" v-if="edit_evening" :disabled="calendarTitleLoader.evening_text">{{ calendarTitleLoader.evening_text ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="capsule_elm mt-2 mb-2">
                                                <h5>Show Evening Label</h5>
                                                <label for="evening_label" class="switch_option capsule_btn ml-auto">
                                                    <input type="checkbox" id="evening_label" :true-value="1" :false-value="0" v-model="form.calendar_settings.has_evening_label" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="session" style="background-color: var(--var-calendar-evening-icon-color-light); color: var(--var-calendar-evening-text-color);">Evening</span>
                                            </div>
                                            <div class="setting_wpr">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.evening_text_color = defaultForm.calendar_settings.evening_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_evening_text_color" v-model="form.calendar_settings.evening_text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.evening_text_color" :classes="{ 'has-error': errors.calendar_evening_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_evening_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Tag Color <a class="reset-default" @click="form.calendar_settings.evening_icon_color = defaultForm.calendar_settings.evening_icon_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_evening_icon_color" v-model="form.calendar_settings.evening_icon_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.evening_icon_color" :classes="{ 'has-error': errors.calendar_evening_icon_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_evening_icon_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="setup_controller">
                                            <h4 @click="edit_other = true;" v-if="!edit_other">
                                                {{ form.calendar_settings.other_text }}
                                                <span class="edit"><i class="fas fa-pen"></i></span>
                                                <a class="reset-default ml-auto" @click="form.calendar_settings.other_text = defaultForm.calendar_settings.other_text">Reset to default</a>
                                            </h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="calendar_other_text" v-model="form.calendar_settings.other_text" placeholder="Calendar other text" rules="required" label="calendar other text" />
                                                    <ErrorMessage name="calendar_other_text" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('other_text', 'edit_other')" v-if="edit_other" :disabled="calendarTitleLoader.other_text">{{ calendarTitleLoader.other_text ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="capsule_elm mt-2 mb-2">
                                                <h5>Show Other Label</h5>
                                                <label for="other_label" class="switch_option capsule_btn ml-auto">
                                                    <input type="checkbox" id="other_label" :true-value="1" :false-value="0" v-model="form.calendar_settings.has_other_label" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="session" style="background-color: var(--var-calendar-other-icon-color-light); color: var(--var-calendar-other-text-color);">Other</span>
                                            </div>
                                            <div class="setting_wpr">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.other_text_color = defaultForm.calendar_settings.other_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_other_text_color" v-model="form.calendar_settings.other_text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.other_text_color" :classes="{ 'has-error': errors.calendar_other_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_other_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Tag Color <a class="reset-default" @click="form.calendar_settings.other_icon_color = defaultForm.calendar_settings.other_icon_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_other_icon_color" v-model="form.calendar_settings.other_icon_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.other_icon_color" :classes="{ 'has-error': errors.calendar_other_icon_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_other_icon_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import constants from '@/utils/Constant'

export default {
    name: 'task-setting',

    data () {
        return  {
            session_label: 1,
            form: {
                calendar_settings: {
                    title: 'Today\'s Tasks!',
                    event_color: '#ffe8c1',
                    text_color: '#121525',
                    morning_text_color: '#121525',
                    morning_icon_color: '#ff7553',
                    afternoon_text_color: '#121525',
                    afternoon_icon_color: '#f2a31d',
                    evening_text_color: '#121525',
                    evening_icon_color: '#548fe3',
                    other_text_color: '#121525',
                    other_icon_color: '#5a5a5a',
                    event_tag_color: '#ecf4ff',
                    event_tag_text_color: '#2f7eed',
                    event_cta_color: '#2f7eed',
                    morning_text: 'Your Morning Activities',
                    afternoon_text: 'Your Afternoon Activities',
                    evening_text: 'Your Evening Activities',
                    other_text: 'Your Other Activities',
                    has_morning_label: 1,
                    has_afternoon_label: 1,
                    has_evening_label: 1,
                    has_other_label: 1,
                },
            },
            defaultForm: {
                calendar_settings: {
                    title: 'Today\'s Tasks!',
                    event_color: '#ffe8c1',
                    text_color: '#121525',
                    morning_text_color: '#121525',
                    morning_icon_color: '#ff7553',
                    afternoon_text_color: '#121525',
                    afternoon_icon_color: '#f2a31d',
                    evening_text_color: '#121525',
                    evening_icon_color: '#548fe3',
                    other_text_color: '#121525',
                    other_icon_color: '#5a5a5a',
                    event_tag_color: '#ecf4ff',
                    event_tag_text_color: '#2f7eed',
                    event_cta_color: '#2f7eed',
                    morning_text: 'Your Morning Activities',
                    afternoon_text: 'Your Afternoon Activities',
                    evening_text: 'Your Evening Activities',
                    other_text: 'Your Other Activities',
                    has_morning_label: 1,
                    has_afternoon_label: 1,
                    has_evening_label: 1,
                    has_other_label: 1,
                },
            },
            edit_morning: false,
            edit_afternoon: false,
            edit_evening: false,
            edit_other: false,
            loaded: false,
        };
    },

    props: {
        modelValue: Boolean,
        type: Number,
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue (val) {
            if (val) {
                const vm = this;
                vm.resetForm();

                document.body.classList.add('modal-open');

                setTimeout(function () {
                    vm.loaded = true;
                }, 2000);
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    components: {
        Field,
        Form,
        ErrorMessage,
    },

    computed: mapState({
        clientPortal: state => state.clientPortalModule.clientPortal,
        updateLoader: state => state.clientPortalModule.updateLoader,
        calendarTitleLoader: state => state.clientPortalModule.calendarTitleLoader,
    }),

    mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
        },

    methods: {
        ...mapActions({
            updatePortalSetting: 'clientPortalModule/updatePortalSetting',
            updateCalendarTitle: 'clientPortalModule/updateCalendarTitle',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        resetForm () {
            const vm = this;

            const calendarSettings = {
                                        title: 'Today\'s Tasks!',
                                        event_color: '#ffe8c1',
                                        text_color: '#121525',
                                        morning_text_color: '#121525',
                                        morning_icon_color: '#ff7553',
                                        afternoon_text_color: '#121525',
                                        afternoon_icon_color: '#f2a31d',
                                        evening_text_color: '#121525',
                                        evening_icon_color: '#548fe3',
                                        other_text_color: '#121525',
                                        other_icon_color: '#5a5a5a',
                                        event_tag_color: '#ecf4ff',
                                        event_tag_text_color: '#2f7eed',
                                        event_cta_color: '#2f7eed',
                                        morning_text: 'Your Morning Activities',
                                        afternoon_text: 'Your Afternoon Activities',
                                        evening_text: 'Your Evening Activities',
                                        other_text: 'Your Other Activities',
                                        has_morning_label: 1,
                                        has_afternoon_label: 1,
                                        has_evening_label: 1,
                                        has_other_label: 1,
                                    };

            vm.form = {
                id: vm.clientPortal.id ? vm.clientPortal.id : 0,
                calendar_settings: vm.clientPortal.calendar_settings ? vm.clientPortal.calendar_settings : calendarSettings,
            };

            if (vm.form.calendar_settings.other_text == undefined) {
                vm.form.calendar_settings.title                 = 'Today\'s Tasks!';
                vm.form.calendar_settings.other_text            = 'Your Other Activities';
                vm.form.calendar_settings.has_morning_label     = 1;
                vm.form.calendar_settings.has_afternoon_label   = 1;
                vm.form.calendar_settings.has_evening_label     = 1;
                vm.form.calendar_settings.has_other_label       = 1;
                vm.form.calendar_settings.other_text_color      = '#121525';
                vm.form.calendar_settings.other_icon_color      = '#5a5a5a';
            }
        },

        handleSubmit (form, { setFieldError }) {
            const vm = this;
            const params = JSON.parse(JSON.stringify(vm.form));

            vm.updatePortalSetting(params).then((result) => {
                if (result) {
                    vm.closeModal();
                }
            });
        },

        handleCalendarTitleUpdate (name , tab) {
            const vm        = this;
            const form      = vm.$refs['client-portal-setting-form'];
            const params    = {};
            const field     = `calendar_${name}`;

            params.setFieldError = form.setFieldError;

            form.validate().then((result) => {
                if (result.valid) {
                    params[name]  = vm.form.calendar_settings[name];
                    params.field  = name;
                    params[field] = vm.form.calendar_settings[name];

                    vm.updateCalendarTitle(params).then((resp) => {
                        if (resp) {
                            vm[tab] = false;
                        }
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
    .modal_body .tab_content{
        height: 100%;
    }
    .modal_body .tab_content :deep(.setting_wpr){
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal_body .tab_content :deep(.setting_wpr .section_wpr:last-of-type){
        margin-top: auto;
        padding-bottom: 50px;
    }

    .modal_container {
        height: 100% !important;
    }

    .client-portal-setting-form {
        height: calc(100% - 153px);
    }
    .calendar_activity_setting li{
        padding: 15px 20px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        margin-bottom: 30px;
    }
    .calendar_activity_setting li .setup_controller{
        flex: 1 1 auto;
    }
    .calendar_activity_setting li .setup_controller .rename_fld{
        display: flex;
        align-items: center;
        background: #f7faff;
        padding-right: 56px;
        position: relative;
        margin-bottom: 15px;
    }
    .calendar_activity_setting li .setup_controller .rename_fld .tab-edit-container{
        flex: 1 1 auto;
    }
    .calendar_activity_setting li .setup_controller .rename_fld .tab-edit-container input{
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 12px 20px;
        background: transparent;
    }
    .calendar_activity_setting li .setup_controller .rename_fld button.save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .calendar_activity_setting li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
    }
    .calendar_activity_setting li h4 .edit {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        font-size: 10px;
        line-height: 20px;
        display: block;
        text-align: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #5a5a5a;
        cursor: pointer;
        border-radius: 50%;
        margin: 0 0 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .calendar_activity_setting li:hover h4 .edit {
        opacity: 1;
    }
    .calendar_activity_setting li .session{
        background: rgba(44, 62, 80, 0.1);
        padding: 5px 15px;
        margin-left: 15px;
        border-radius: 8px;
        position: relative;
        z-index: 0;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }
</style>
